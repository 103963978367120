/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import logo from '../Recursos/logo_oscuro.svg'
import logo_redondo from '../Recursos/logo_oscuro.svg'
import logo_blanco from '../Recursos/logo_blanco.svg'
import {useContext, useEffect} from "react";
import {usuarioContext} from "../App";
import {funSalir} from "../Servicios/Auth/funSalir";
import {useGetToken} from "../Modulo_Notificaciones/Hooks/useGetToken";
import {useEscucharNotificacion} from "../Modulo_Notificaciones/Hooks/useEscucharNotificacion";
import {actualizarDoc} from "../Servicios/BD/actualizarDoc";
import {
    Article,
    CampaignOutlined,
    Comment,
    DashboardOutlined,
    Houseboat,
    Layers,
    LocalAtmOutlined,
    People,
    StarRate,
    WhatsApp
} from "@mui/icons-material";
import Seccion_Anuncios from "./Secciones/Seccion_Anuncios";
import Seccion_Servicios from "./Secciones/Seccion_Servicios";
import Seccion_Testimonios from "./Secciones/Seccion_Testimonios";
import Seccion_Textos_Home from "./Secciones/Seccion_Textos_Home";
import Seccion_Textos_Tours from "./Secciones/Seccion_Textos_Tours";
import Seccion_Tours from "./Secciones/Seccion_Tours";
import Seccion_Textos_Nosotros from "./Secciones/Seccion_Textos_Nosotros";
import Seccion_Equipo from "./Secciones/Seccion_Equipo";
import Seccion_Asesores from "./Secciones/Seccion_Asesores";
import Seccion_Resenas from "./Secciones/Seccion_Resenas";
import Seccion_Pagos from "./Secciones/Seccion_Pagos";
import Seccion_Adicionales from "./Secciones/Seccion_Adicionales";

const Dashboard = () => {
    const cData = useContext(usuarioContext)
    const {Notificador} = useEscucharNotificacion()
    const {token} = useGetToken()

    const irPerfil = () => {
        // alert('ir Perfil')
    }


    useEffect(() => {
        if (cData.usuario) {
            let usuario = cData.usuario
            if (token && usuario.token !== token) {
                actualizarDoc('usuarios', usuario.id, {token: token}).then((dox) => {
                    if (dox.res) {
                        console.log('cambio token')
                    }
                })
            }

        }

    }, [token]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Notificador/>

            <DashboardBase secciones={secciones}
                           credenciales={['Pagos', 'Anuncios', 'Servicios', 'Tours', 'Testimonios', 'Reseñas', 'Equipo', 'Asesores', 'Adicionales', 'Home', 'Tours', 'Nosotros',]}>
                <MenuLateral logo={logo} clickPerfil={irPerfil} logoRedondo={logo_redondo} logoBlanco={logo_blanco}
                             usuario={cData.usuario} salir={funSalir}/>
            </DashboardBase>


        </Grid>
    )

}
export default Dashboard

const secciones = [
    {
        nombre: 'Pagos',
        Componente: <Seccion_Pagos/>,
        icono: LocalAtmOutlined,
        categoria: 'propia',
        camino: '/Pagos',
    },
    {
        nombre: 'Anuncios',
        Componente: <Seccion_Anuncios/>,
        icono: CampaignOutlined,
        categoria: 'Pagina',
        camino: '/Anuncios',
    },
    {
        nombre: 'Servicios',
        Componente: <Seccion_Servicios/>,
        icono: DashboardOutlined,
        categoria: 'Pagina',
        camino: '/Servicios',
    },
    {
        nombre: 'Tours',
        Componente: <Seccion_Tours/>,
        icono: Houseboat,
        categoria: 'Pagina',
        camino: '/Tours',
    },
    {
        nombre: 'Testimonios',
        Componente: <Seccion_Testimonios/>,
        icono: Comment,
        categoria: 'Pagina',
        camino: '/Testimonios',
    },
    {
        nombre: 'Reseñas',
        Componente: <Seccion_Resenas/>,
        icono: StarRate,
        categoria: 'Pagina',
        camino: '/Resenas',
    },
    {
        nombre: 'Equipo',
        Componente: <Seccion_Equipo/>,
        icono: People,
        categoria: 'Pagina',
        camino: '/Equipo',
    },
    {
        nombre: 'Asesores',
        Componente: <Seccion_Asesores/>,
        icono: WhatsApp,
        categoria: 'Pagina',
        camino: '/Asesores',
    },
    {
        nombre: 'Adicionales',
        Componente: <Seccion_Adicionales/>,
        icono: Article,
        categoria: 'Adicionales',
        camino: '/Adicionales',
    },
    {
        nombre: 'Home',
        Componente: <Seccion_Textos_Home/>,
        icono: Layers,
        categoria: 'Textos',
        camino: '/ConfiguracionesHome',
    },
    {
        nombre: 'Tours',
        Componente: <Seccion_Textos_Tours/>,
        icono: Layers,
        categoria: 'Textos',
        camino: '/ConfiguracionesTours',
    },
    {
        nombre: 'Nosotros',
        Componente: <Seccion_Textos_Nosotros/>,
        icono: Layers,
        categoria: 'Textos',
        camino: '/Nosotros',
    },
    {
        nombre: 'Redes Y Asesores',
        Componente: <Seccion_Textos_Nosotros/>,
        icono: Layers,
        categoria: 'Textos',
        camino: '/Redes',
    },
]