/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Link, ShortText, Title} from "@mui/icons-material";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useEffect, useState} from "react";


const Formulario_Adicional = ({...props}) => {
    const [value, setValue] = useState('');


    useEffect(() => {
        props.props.setValue('textoEstilo', value)
    }, [value]);

    useEffect(() => {
       setValue(props?.textoEstilo)
    },[])
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Title} nombre={'Nombre del link'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <ReactQuill theme="snow" value={value} onChange={setValue} preserveWhitespace

                    />
                </Grid>




            </Grid>
        </form>

    )

}
export default Formulario_Adicional