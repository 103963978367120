/**************************************************
 * Nombre:       Dialogo_Producto_Empresaria
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_blanco.svg'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Asesor from "../Formularios/Formulario_Asesor";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";

const Dialogo_Asesor = ({realizado, datos, cerrar}) => {
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            let obj = entidad
            abrirCargador('Guardando Cambios')
            obj.fecha = new Date().getTime()

            subirImagenFile(entidad.img, 'asesor').then((dox) => {
                if (dox.res) {
                    obj.img = dox.data
                    guardarAsesor(obj)
                }
            })

        })

    }


    const guardarAsesor = (obj) => {
        guardarDoc('asesores', obj).then((des) => {
            if (des.res) {
                cerrar()
                cerrarCargador()
                alert('Cambios guardados con exito')
                realizado()
            } else {
                cerrarCargador()
                alert('Error al subir datos, por favor intentelo nuevamente')
            }
        })
    }


    const borrar = () => {
        abrirCargador('Borrando tour')
        borrarDoc('asesores', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Asesor props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar asesor</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Asesor